export const getters = {
	getUser: 'user',
	getTemplate: 'getTemplate',
	getTemplates: 'templates',
	getSlide: 'getSlide',
	getContentFiles: 'contentFiles',
	getUiComponent: 'uiComponents',
	getCurrentSettingsMenu: 'getCurrentSettingsMenu',
	requestError: 'requestError'
};

export const mutations = {
	setUser: 'setUser',
	setTemplates: 'setTemplates',
	addTemplate: 'addTemplate',
	deleteTemplate: 'deleteTemplate',
	addSlide: 'addSlide',
	setSlideImage: 'setSlideImage',
	orderSlides: 'orderSlides',
	deleteSlide: 'deleteSlide',
	addUiComponent: 'addUiComponent',
	setUiComponentProperties: 'setUiComponentProperties',
	setContentFiles: 'setContentFiles',
	showSettingsMenu: 'showSettingsMenu',
	setRequestError: 'setRequestError'
};

export const actions = {
	setTemplates: 'setTemplates',
	addTemplate: 'addTemplate',
	deleteTemplate: 'deleteTemplate',
	addSlide: 'addSlide',
	createThumbnailForSlide: 'createThumbnailForSlide',
	orderSlides: 'orderSlides',
	deleteSlide: 'deleteSlide',
	login: 'login',
	logout: 'logout',
	requestError: 'requestError',
	refreshUser: 'refreshUser',
	addUiComponent: 'addUiComponent',
	setUiComponentProperties: 'setUiComponentProperties',
	showSettingsMenu: 'showSettingsMenu'
};

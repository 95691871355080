import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import { outputChannelResource } from "../../util/resources";
export const namespaceHelpers = createNamespacedHelpers("outputChannels");

export const types = {
  mutations: {
    REQUEST: "outputChannels__REQUEST",
    FAILURE: "outputChannels__FAILURE",
    SUCCESS: "outputChannels__SUCCESS",
    SUCCESS_EDIT: "outputChannels__SUCCESS_EDIT",
    SUCCESS_DELETE: "outputChannels__SUCCESS_DELETE",
    SUCCESS_UPLOAD: "outputChannels__SUCCESS_UPLOAD",
    DELETE_INPUT_CHANNEL: "outputChannels__DELETE_INPUT_CHANNEL",
  },
};

export const outputChannels = {
  namespaced: true,
  state: {
    isLoading: false,
    isLoaded: false,
    data: [],
    error: {},
  },
  mutations: {
    [types.mutations.REQUEST](state) {
      state.isLoading = true;
      state.isLoaded = false;
      state.error = {};
    },

    [types.mutations.FAILURE](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = payload.response.data;
    },

    [types.mutations.SUCCESS](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      state.data = payload.data;
    },

    [types.mutations.SUCCESS_UPLOAD](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      Vue.set(state.data, state.data.length, payload.data);
    },

    [types.mutations.SUCCESS_EDIT](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      const index = state.data.findIndex((val) => val.id === payload.data.id);
      Vue.set(state.data, index, payload.data);
    },

    [types.mutations.SUCCESS_DELETE](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      const index = state.data.findIndex((val) => val.id === payload.data.id);
      Vue.delete(state.data, index);
    },
  },
  getters: {
    outputChannels: (state) => state.data,
    outputChannel: (state) => (id) => state.data.find((val) => val.id === id),
    referencedAuthentication: (state) => {
      let reference = new Set();
      state.data.forEach((channel) => {
        if (channel.authentication) reference.add(channel.authentication.id);
      });
      return reference;
    },
  },
  actions: {
    async load({ commit }) {
      try {
        const res = await outputChannelResource.get("");
        commit(types.mutations.SUCCESS, res);
      } catch (err) {
        commit(types.mutations.FAILURE, err);
      }
    },

    async save({ commit }, dataSource) {
      try {
        const res = await outputChannelResource.post("", dataSource);
        commit(types.mutations.SUCCESS_UPLOAD, res);
        return res;
      } catch (err) {
        commit(types.mutations.FAILURE, err);
        return err;
      }
    },

    async edit({ commit }, dataSource) {
      try {
        const res = await outputChannelResource.put(`/${dataSource.id}`, dataSource);
        commit(types.mutations.SUCCESS_EDIT, res);
        return res;
      } catch (err) {
        commit(types.mutations.FAILURE, err);
        return err;
      }
    },

    async delete({ commit }, dataSource) {
      try {
        const res = await outputChannelResource.delete(`/${dataSource.id}`);
        commit(types.mutations.SUCCESS_DELETE, res);
        return res;
      } catch (err) {
        commit(types.mutations.FAILURE, err);
        return err;
      }
    },
  },
};

import Vue from "vue";
// import {mapState, mapActions} from "vuex";
import {
  namespaceHelpers
} from "@/store/modules/user";
import template from "./login.html";
import {
  API_BASE
} from "@/config/constants";

export default Vue.extend({
  template,

  beforeMount() {
    window.localStorage.removeItem("vuex");
  },

  activated() {
    this.username = "";
    this.password = "";
  },

  data() {
    return {
      username: "",
      password: "",
      showPassword: false
    };
  },

  computed: {
    ...namespaceHelpers.mapState({
      error: state => state.error
    })
  },

  methods: {
    ...namespaceHelpers.mapActions({
      login: "login"
    }),

    async onLogin() {
      const data = new FormData();
      data.append("username", this.username.trim().toLowerCase());
      data.append("password", this.password.trim());
      data.append("grant_type", "password");
      data.append("scope", "ui");

      const res = await this.login(data);
      if (res.status === 200) {
        await this.$router.replace(`/${this.$i18n.locale}/templates`);
      }
    }
  },

  i18n: {
    messages: {
      en: {
        errors: {
          undefined: "",
          ACCOUNT_DISABLED: "Your account is disabled, please contact our support.",
          ACCOUNT_LOCKED: `Your account is not verified - <a href="${API_BASE}/uaa/user/resend-verification?mail={mail}">resend verification?</a>`,
          ACCOUNT_EXPIRED: "Your account has expired, please contact our support.",

          BAD_CREDENTIALS: "The username or password you entered is incorrect.",
          INTERNAL_SERVER_ERROR: "Server error, please contact our support if the problem persists"
        }
      }
    }
  }
});
import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import { schedulerResource } from "../../util/resources";
export const namespaceHelpers = createNamespacedHelpers("scheduler");

export const types = {
  mutations: {
    REQUEST: "scheduler__REQUEST",
    FAILURE: "scheduler__FAILURE",
    SUCCESS: "scheduler__SUCCESS",
    SUCCESS_SINGLE: "scheduler__SUCCESS_SINGLE",
    SUCCESS_EDIT: "scheduler__SUCCESS_EDIT",
    SUCCESS_DELETE: "scheduler__SUCCESS_DELETE",
    SUCCESS_UPLOAD: "scheduler__SUCCESS_UPLOAD",
    DELETE_INPUT_CHANNEL: "scheduler__DELETE_INPUT_CHANNEL"
  }
};

export const scheduler = {
  namespaced: true,
  state: {
    isLoading: false,
    isLoaded: false,
    data: [],
    error: {}
  },
  mutations: {
    [types.mutations.REQUEST](state) {
      state.isLoading = true;
      state.isLoaded = false;
      state.error = {};
    },

    [types.mutations.FAILURE](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = payload.response.data;
    },

    [types.mutations.SUCCESS](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      state.data = payload.data;
    },

    [types.mutations.SUCCESS_SINGLE](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      const index = state.data.findIndex(val => val.id === payload.data.id);
      Vue.set(state.data, index, payload.data);
    },

    [types.mutations.SUCCESS_UPLOAD](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      Vue.set(state.data, state.data.length, payload.data);
    },

    [types.mutations.SUCCESS_EDIT](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      const index = state.data.findIndex(val => val.id === payload.data.id);
      Vue.set(state.data, index, payload.data);
    },

    [types.mutations.SUCCESS_DELETE](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      const index = state.data.findIndex(val => val.id === payload.data.id);
      Vue.delete(state.data, index);
    }
  },
  getters: {
    schedulers: state => state.data,
    scheduler: state => id => state.data.find(val => val.id === id),
    referencedOutputChannels: state => {
      let channels = new Set();
      state.data.forEach(scheduler => {
        channels.add(scheduler.outputChannel.id);
      });
      return channels;
    }
  },
  actions: {
    async loadSingle({ commit }, payload) {
      try {
        const res = await schedulerResource.get(`/${payload.templateId}/${payload.slideId}`);
        commit(types.mutations.SUCCESS_SINGLE, res);
        return res;
      } catch (err) {
        commit(types.mutations.FAILURE, err);
        return null;
      }
    },
    async load({ commit }) {
      try {
        const res = await schedulerResource.get();
        commit(types.mutations.SUCCESS, res);
        return res;
      } catch (err) {
        commit(types.mutations.FAILURE, err);
        return null;
      }
    },
    async save({ commit }, scheduler) {
      try {
        const res = await schedulerResource.post("", scheduler);
        commit(types.mutations.SUCCESS_UPLOAD, res);
        return res;
      } catch (err) {
        commit(types.mutations.FAILURE, err);
        return err;
      }
    },

    async edit({ commit }, scheduler) {
      try {
        const res = await schedulerResource.put(`/${scheduler.id}`, scheduler);
        commit(types.mutations.SUCCESS_EDIT, res);
        return res;
      } catch (err) {
        commit(types.mutations.FAILURE, err);
        return err;
      }
    },

    async delete({ commit }, scheduler) {
      try {
        const res = await schedulerResource.delete(`/${scheduler.id}`);
        commit(types.mutations.SUCCESS_DELETE, res);
        return res;
      } catch (err) {
        commit(types.mutations.FAILURE, err);
        return err;
      }
    }
  }
};

import { createNamespacedHelpers } from 'vuex';
import { actions } from './user/actions';
import { mutations } from './user/mutations';

export const namespaceHelpers = createNamespacedHelpers('user');

export const user = {
	namespaced: true,
	state: {
		isLoading: false,
		isLoaded: false,
		data: {},
		error: {}
	},
	getters: {
		isAuthenticated: (state) => state.data && state.data.refresh_time > new Date().getTime(),
		getAuthentication: (state) => state.data
	},
	mutations,
	actions
};

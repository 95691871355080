import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";
import { user } from "./modules/user";
import { authentication } from "./modules/authentication";
import { inputChannels as inputChannelsNew } from "./modules/inputChannelsNew";
import { mediaPool } from "./modules/mediaPool";
import { outputChannels } from "./modules/outputChannels";
import { profile } from "./modules/profile";
import { templates } from "./modules/templates";
import { scheduler } from "./modules/scheduler";
import { UndoRedo } from "./UndoRedoManager";

Vue.use(Vuex);

const modules = [
  "user",
  "templates",
  "profile",
  "mediaPool",
  "outputChannels",
  "inputChannelsNew",
  "authentication",
  "scheduler",
];
const vuexPersist = new VuexPersistence({
  strictMode: true,
  storage: window.localStorage,
  modules: modules,
});

UndoRedo.registerModules(modules);

export const store = new Vuex.Store({
  modules: {
    authentication: authentication,
    user: user,
    templates: templates,
    profile: profile,
    mediaPool: mediaPool,
    inputChannelsNew: inputChannelsNew,
    outputChannels: outputChannels,
    scheduler: scheduler,
  },
  state: {
    requestError: null,
  },

  mutations: {
    ...mutations,
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION,
    emptyState() {},
  },

  actions: {
    ...actions,
    async undo({ commit, state }, payload) {
      await UndoRedo.undo({ commit, state }, payload);
    },

    async redo({ commit }, payload) {
      await UndoRedo.redo({ commit }, payload);
    },
  },

  getters: {
    ...getters,
  },

  plugins: [vuexPersist.plugin],
  strict: true,
});

<template>
  <v-dialog class="dialog-overlay" v-model="visible" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ text }}</span>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" text @click="visible = false">Close</v-btn>
        <v-btn
          color="red darken-1"
          text
          @click.native="
            visible = false;
            $emit('delete-object', currentId);
          "
          data-cy="delete-dialog-delete-btn"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["text", "dialogId"],
  data() {
    return {
      currentId: null,
      visible: false,
    };
  },
  mounted() {
    this.id = this._uid;
    this.$root.$on("showDeleteDialog", (value) => {
      if (this.dialogId === value.dialogId) {
        this.currentId = value.currentId;
        this.visible = value.visible;
      }
    });
  },
};
</script>

export default {
  mutations: {
    REQUEST: "template__REQUEST",
    FAILURE: "template__FAILURE",
    SUCCESS: "template__SUCCESS",
    SUCCESS_ADD: "template__SUCCESS_ADD",
    SUCCESS_EDIT: "template__SUCCESS_EDIT",
    SUCCESS_DELETE: "template__SUCCESS_DELETE",
    SUCCESS_EDIT_SLIDE: "template__SUCCESS_EDIT_SLIDE",
    SUCCESS_ADD_SLIDE: "template__SUCCESS_ADD_SLIDE",
    SUCCESS_DUPLICATE_SLIDE: "template__SUCCESS_DUPLICATE_SLIDE",
    SUCCESS_DELETE_SLIDE: "template__SUCCESS_DELETE_SLIDE",
    SUCCESS_ORDER_SLIDES: "template__SUCCESS_ORDER_SLIDES",
    SUCCESS_ADD_COMPONENT: "template__SUCCESS_ADD_COMPONENT",
    SUCCESS_DELETE_COMPONENT: "template__SUCCESS_DELETE_COMPONENT",
    SUCCESS_EDIT_COMPONENT: "template__SUCCESS_EDIT_COMPONENT",
    SUCCESS_EDIT_ALL_COMPONENT: "template__SUCCESS_EDIT_ALL_COMPONENT",
    SUCCESS_MOVE_COMPONENT: "template__SUCCESS_MOVE_COMPONENT"
  }
};

export const theme = {
  //
  // primary: '#8BC34A',
  // primary: '#9E9E9E',
  secondary: "#FFF",
  primary: "#8BC34A", //'#84C156',
  accent: "#424242"
};
// #72b742
// #72b729

import types from "./types";
import { debounce } from "lodash";
import { templateResource } from "@/util/resources";
import { UndoRedo } from "../../UndoRedoManager";
import { schedulerResource } from "../../../util/resources";
import { generateId } from "@/util/helpers";

export const actions = {
  async load({ commit }) {
    try {
      const response = await templateResource.get("/templates");
      commit(types.mutations.SUCCESS, response);
    } catch (error) {
      commit(types.mutations.FAILURE, error);
    }
  },

  async save({ commit }, payload) {
    try {
      const response = await templateResource.post("/templates", payload);
      commit(types.mutations.SUCCESS_ADD, response);
    } catch (error) {
      commit(types.mutations.FAILURE, error);
    }
  },

  async delete({ commit, state }, payload) {
    try {
      const template = state.data.find((template) => template.id === payload.id);
      for (let slide of template.slides) {
        schedulerResource.delete(`/${payload.id}/${slide.id}`);
      }

      await templateResource.delete(`/templates/${payload.id}`); // no response on delete
      commit(types.mutations.SUCCESS_DELETE, payload);
    } catch (error) {
      commit(types.mutations.FAILURE, error);
    }
  },

  createSlideThumbnail: debounce(async ({ commit }, payload) => {
    /* TODO optimize preview image generation
    const previewImage = (
      await rendererResource({
        url: `/${payload.templateId}/${payload.slideId}/preview`,
        method: "POST",
        responseType: "blob",
      })
    ).data;*/
    commit(types.mutations.SUCCESS_EDIT_SLIDE, {
      ...payload /*, previewImageBlob: URL.createObjectURL(previewImage)*/,
    });
  }, 500),

  async editTemplate({ commit }, payload) {
    try {
      const response = await templateResource.patch(`/templates/${payload.id}`, payload);
      commit(types.mutations.SUCCESS_EDIT, response);
    } catch (error) {
      commit(types.mutations.FAILURE, error);
    }
  },

  async editSlide({ commit, dispatch }, payload) {
    await commit(types.mutations.SUCCESS_EDIT_SLIDE, payload);
    await dispatch("persist", payload);
    if (payload.color) {
      dispatch("createSlideThumbnail", payload);
    }
  },

  addSlide({ commit }, payload) {
    commit(types.mutations.SUCCESS_ADD_SLIDE, payload);
  },

  duplicateSlide({ commit }, payload) {
    commit(types.mutations.SUCCESS_DUPLICATE_SLIDE, payload);
  },

  orderSlides({ commit, dispatch }, payload) {
    commit(types.mutations.SUCCESS_ORDER_SLIDES, payload);
    dispatch("persist", payload);
  },

  deleteSlide({ commit, dispatch }, payload) {
    commit(types.mutations.SUCCESS_DELETE_SLIDE, payload);
    dispatch("persist", payload);
  },

  async addComponent({ commit, dispatch }, payload) {
    const component = {
      id: generateId(),
      height: 0.2,
      width: 0.2 * (payload.canvas.offsetWidth / payload.canvas.offsetHeight),
      top: 0.4,
      left: 0.4,
      type: payload.type,
      visible: true,
      external: payload.external,
      dataSourceId: payload.dataSourceId,
      dataSourceGroup: payload.dataSourceGroup,
      dataSourceSelector: payload.dataSourceSelector,
      dataSourceIndex: payload.dataSourceIndex,
      properties: {
        url: payload.url,
        key: payload.key,
        text: payload.text,
        shapeType: payload.shapeType,
        locked: false,
        color: payload.color,
        font: payload.font,
        fontSize: payload.fontSize,
        lineHeight: payload.lineHeight,
        fillingMode: payload.fillingMode,
        regex: payload.regex,
        regexMatch: payload.regexMatch,
        stringFormatter: payload.stringFormatter,
        prefixString: payload.prefixString,
        suffixString: payload.suffixString,
        channelNew: payload.channelNew,
        relativeSelectorIndex: payload.relativeSelectorIndex,
        ...(payload.qrcode
          ? {
              qrcode: true,
              qrBackgroundColor: payload.qrBackgroundColor,
              qrForegroundColor: payload.qrForegroundColor,
            }
          : {}),
      },
      layer: 10000,
    };
    await commit(types.mutations.SUCCESS_ADD_COMPONENT, { ...payload, component: component });
    await dispatch("selectComponent", component.id);
    // await dispatch(types.mutations.SUCCESS_EDIT_ALL_COMPONENT, payload);
    // commit(types.mutations.SUCCESS_EDIT_ALL_COMPONENT);
    if (!payload.multiple) {
      await dispatch("persist", payload);
      dispatch("createSlideThumbnail", payload);
    }
  },

  async deleteComponent({ commit, dispatch }, payload) {
    await commit(types.mutations.SUCCESS_DELETE_COMPONENT, payload);
    await dispatch("persist", payload);
    dispatch("createSlideThumbnail", payload);
  },

  async updateComponentOrder({ commit, dispatch }, payload) {
    commit(types.mutations.SUCCESS_EDIT_ALL_COMPONENT, payload);
    await dispatch("persist", payload);
    dispatch("createSlideThumbnail", payload);
  },

  async moveComponentToFront({ commit, dispatch }, payload) {
    await commit(
      types.mutations.SUCCESS_MOVE_COMPONENT,
      Object.assign(payload, {
        direction: "front",
      })
    );
    await dispatch("persist", payload);
    dispatch("createSlideThumbnail", payload);
  },

  async moveComponentToBack({ commit, dispatch }, payload) {
    await commit(
      types.mutations.SUCCESS_MOVE_COMPONENT,
      Object.assign(payload, {
        direction: "back",
      })
    );
    await dispatch("persist", payload);
    dispatch("createSlideThumbnail", payload);
  },

  async editComponent({ commit, dispatch, state }, payload) {
    UndoRedo.pushState("templates", state.data);
    await commit(types.mutations.SUCCESS_EDIT_COMPONENT, payload);
    await dispatch("persist", payload);
    dispatch("createSlideThumbnail", payload);
  },

  persist: debounce(async ({ state, commit }, payload) => {
    try {
      await templateResource.put(
        `/templates/${payload.templateId}`,
        state.data.find((e) => e.id == payload.templateId)
      );
    } catch (error) {
      commit(types.mutations.FAILURE, error);
    }
  }, 200),

  selectComponent({ commit, state }, componentId) {
    if (state.currentSelectedComponent !== componentId) {
      commit("setSelectedComponent", componentId);
    }
  },

  showMenu({ commit, state }, componentId) {
    if (state.currentEditMenuComponent !== componentId) {
      commit("setCurrentEditMenuComponent", componentId);
    }
  },
};

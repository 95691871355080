import Vue from 'vue';
import { namespaceHelpers } from '@/store/modules/profile';

import template from './profile.html';

export default Vue.extend({
	template,
	async created() {
		this.loadProfile();
	},

	mounted() {
		this.$root.$emit('hideFooter', false);
	},

	computed: {
		...namespaceHelpers.mapState({
			data: (state) => state.data
		})
	},
	methods: {
		...namespaceHelpers.mapActions({ loadProfile: 'loadProfile' }),
	}
});

import { createNamespacedHelpers } from 'vuex';
import { profileResource } from '../../../util/resources';

export const namespaceHelpers = createNamespacedHelpers('profile');

export const types = {
	mutations: {
		SUCCESS: 'setProfile__SUCCESS',
		RESET: 'setProfile__SUCCESS_LOGOUT',
		FAILURE: 'setProfile__FAILURE',
		REQUEST: 'setProfile__REQUEST'
	}
};

export const profile = {
	namespaced: true,
	state: {
		isLoading: false,
		isLoaded: false,
		data: {},
		error: {}
	},
	actions: {
		async loadProfile({ commit }) {
			try {
				const data = await profileResource.get('/account');
				commit(types.mutations.SUCCESS, data);
			} catch (error) {
				commit(types.mutations.FAILURE, error);
			}
		}
	},
	mutations: {
		[types.mutations.SUCCESS](state, payload) {
			state.data = payload.data;
			state.isLoading = false;
			state.isLoaded = true;
			state.error = {};
		},

		[types.mutations.FAILURE](state, payload) {
			state.isLoading = false;
			state.isLoaded = true;
			state.error = payload.response.data;
		}
	}
};

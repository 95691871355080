import { mutations } from '../types';
import { UNDO } from '../UndoRedoManager';
import Vue from 'vue';

export default {
	[mutations.setRequestError](state, payload) {
		state.requestError = payload;
	},

	//settings menu switch
	[mutations.showSettingsMenu](state, payload) {
		state.currentSettingsMenu = payload.menuId;
	},

	[UNDO](state, payload) {
		state[payload.module].data = Object.assign(Array.isArray(payload.data) ? [] : {}, payload.data);
		Vue.set(state[payload.module], 'data', Object.assign(Array.isArray(payload.data) ? [] : {}, payload.data));
	}
};

import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { LANGUAGES } from './config/constants';

Vue.use(VueI18n);

export const i18n = new VueI18n({
	locale: LANGUAGES[0],
	messages: { en: {} }
});

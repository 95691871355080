import Vue from 'vue';
import template from './preview.html';
import { namespaceHelpers } from '@/store/modules/templates';
import './preview.scss';
import fscreen from 'fscreen';
export default Vue.extend({
	template,
	computed: {
		...namespaceHelpers.mapGetters({
			template: 'template'
		}),
		slide() {
			return this.template(this.$route.params.id).slides.find((elem) => elem.id == this.$route.params.slideId);
		},
		canvas() {
			return document.getElementById('preview-canvas');
		}
	},
	data() {
		return {
			fullscreen: false
		};
	},

	methods: {
		triggerFullscreen() {
			if (this.fullscreen) {
				fscreen.exitFullscreen();
				this.canvas.style.transform = 'scale(0.5)';
			} else {
				fscreen.requestFullscreen(document.getElementById('preview-canvas'));
				this.canvas.style.transform = 'scale(2)';
			}
			this.fullscreen = !this.fullscreen;
		}
	}
});

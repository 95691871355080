<template>
  <v-img class="image--fallback" v-bind="$props" :src="imgSrc" @error="hasError"> </v-img>
</template>

<script>
export default {
  mounted() {
    this.imgSrc = this.src;
  },
  data() {
    return {
      imgSrc: null,
      err: false,
      vm: null,
      info: null,
    };
  },
  props: {
    stopPropagation: {
      type: Boolean,
      default: true,
    },
    src: String,
    "lazy-src": String,
    width: [String, Number],
    height: [String, Number],
    contain: String,
  },
  methods: {
    hasError() {
      this.imgSrc = this["lazy-src"];
    },
  },
  errorCaptured(err, vm, info) {
    this.err = err;
    this.vm = vm;
    this.info = info;
    return !this.stopPropagation;
  },
};
</script>

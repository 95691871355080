import Vue from 'vue';
import template from './successPage.html';
import './successPage.scss';

export default Vue.extend({
	template,

	i18n: {
		messages: {
			en: {
				actions: {
					verify: 'Thanks for verifying your email address!',
					register: 'Thanks for registering on our site!'
				}
			}
		}
	}
});

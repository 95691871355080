import { LoadingState } from "@/config/loading-state";
import { store } from "../store";

// Helper to set global loading state
export const setLoading = (isLoading = false) => {
  LoadingState.$emit("toggle", isLoading);
};

export const getAuthentication = () => {
  if (store.state.user.data.access_token) {
    return `Bearer ${store.state.user.data.access_token}`;
  }
};

export const isEmpty = (object) => {
  for (let x in object) {
    return false;
  }
  return true;
};

export const generateId = () =>
  Date.now().toString(36) +
  Math.random()
    .toString(36)
    .substr(2, 5);

export function getMaxLines(element) {
  var availHeight = element.clientHeight,
    lineHeight = getLineHeight(element);

  let lines = Math.floor(availHeight / lineHeight);
  let remainder = availHeight / lineHeight - lines;
  return Math.max(lines, 0) + (remainder > 0.5 ? 1 : 0);
}

/**
 * Returns the line-height of an element as an integer.
 */
function getLineHeight(elem) {
  var lh = computeStyle(elem, "line-height");
  if (lh == "normal") {
    // Normal line heights vary from browser to browser. The spec recommends
    // a value between 1.0 and 1.2 of the font size. Using 1.1 to split the diff.
    lh = parseInt(computeStyle(elem, "font-size")) * 1.2;
  }
  return parseInt(lh);
}

function computeStyle(elem, prop) {
  if (!window.getComputedStyle) {
    //eslint-disable-next-line
    window.getComputedStyle = function(el, pseudo) {
      this.el = el;
      this.getPropertyValue = function(prop) {
        var re = /(-([a-z]){1})/g;
        if (prop == "float") prop = "styleFloat";
        if (re.test(prop)) {
          prop = prop.replace(re, function() {
            return arguments[2].toUpperCase();
          });
        }
        return el.currentStyle && el.currentStyle[prop] ? el.currentStyle[prop] : null;
      };
      return this;
    };
  }

  return window.getComputedStyle(elem, null).getPropertyValue(prop);
}

export const createMovableDialogs = () => {
  (function() {
    // make vuetify dialogs movable
    const d = {};
    document.addEventListener("mousedown", (e) => {
      const closestDialog = e.target.closest(".v-dialog.v-dialog--active");
      if (
        e.button === 0 &&
        closestDialog != null &&
        e.target.classList.contains("v-card__title") &&
        closestDialog.classList.contains("draggable")
      ) {
        // element which can be used to move element
        d.el = closestDialog; // element which should be moved
        d.mouseStartX = e.clientX;
        d.mouseStartY = e.clientY;
        d.elStartX = d.el.getBoundingClientRect().left;
        d.elStartY = d.el.getBoundingClientRect().top;
        d.el.style.position = "fixed";
        d.el.style.margin = 0;
        d.oldTransition = d.el.style.transition;
        d.el.style.transition = "none";
      }
    });
    document.addEventListener("mousemove", (e) => {
      if (d.el === undefined) return;
      d.el.style.left =
        Math.min(
          Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
          window.innerWidth - d.el.getBoundingClientRect().width
        ) + "px";
      d.el.style.top =
        Math.min(
          Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
          window.innerHeight - d.el.getBoundingClientRect().height
        ) + "px";
    });
    document.addEventListener("mouseup", () => {
      if (d.el === undefined) return;
      d.el.style.transition = d.oldTransition;
      d.el = undefined;
    });
    setInterval(() => {
      // prevent out of bounds
      const dialog = document.querySelector(".v-dialog.v-dialog--active");
      if (dialog === null) return;
      dialog.style.left =
        Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + "px";
      dialog.style.top =
        Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + "px";
    }, 100);
  })();
};

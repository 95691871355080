import Vue from "vue";

export default Vue.extend({
  template: `
    <v-main>
        <h1 class="mb-2">Not found...</h1>
        <p>Sorry <i class="fa fa-frown-o"></i></p>
    </v-main>
  `,
});

import { UndoRedo } from "../../UndoRedoManager";

export const getters = {
  canUndo: () => UndoRedo.canUndo("templates"),
  canRedo: () => UndoRedo.canRedo("templates"),
  data: (state) => state.data,
  template: (state) => (id) => state.data.find((elem) => elem.id == id),
  getInputChannels: (state, getters) => (id, slideId) => {
    const slide = getters.slide(id, slideId);
    if (!slide) {
      return [];
    }
    let channels = new Set();
    slide.components.forEach((element) => {
      channels.add(element.dataSourceId);
    });
    return channels;
  },
  slide: (state) => (id, slide) =>
    state.data
      .find((elem) => elem.id == id)
      .slides.find((elem) => elem.id == slide),
  component: (state) => (id, slide, component) =>
    state.data
      .find((elem) => elem.id == id)
      .slides.find((elem) => elem.id == slide)
      .components.find((elem) => elem.id == component),
  referencedMedia: (state) => {
    let media = new Set();
    state.data.forEach((template) => {
      template.slides.forEach((slide) => {
        slide.components.forEach((element) => {
          media.add(element.properties.font);
          media.add(element.properties.url);
        });
      });
    });
    return media;
  },
  currentSelectedComponent: (state) => state.currentSelectedComponent,
  currentEditMenuComponent: (state) => state.currentEditMenuComponent,
  referencedInputChannels: (state) => {
    let channels = new Set();
    state.data.forEach((template) => {
      template.slides.forEach((slide) => {
        slide.components.forEach((element) => {
          channels.add(element.dataSourceId);
        });
      });
    });
    return channels;
  },
};

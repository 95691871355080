<template>
  <v-app id="app">
    <NavigationModule></NavigationModule>

    <keep-alive v-if="cacheView">
      <router-view ref="routerView" :isLoading="isLoading"></router-view>
    </keep-alive>
    <router-view v-else ref="routerView" :isLoading="isLoading"></router-view>

    <!-- load must be positioned after routerView to ensure proper placement views e.g. template editor -->

    <LoaderModule v-show="isLoading"></LoaderModule>
    <v-footer v-if="showFooter">
      <v-layout justify-center>
        <v-flex lighten-2 py-2 text-center xs12 text-xs>
          &copy;{{ new Date() | moment("YYYY") }} —
          <strong>Alphaport</strong>
        </v-flex>
      </v-layout>
    </v-footer>
  </v-app>
</template>

<script>
import LoaderModule from "@/components/Loader/loader";
import NavigationModule from "@/components/Navigation/navigation";
import { LoadingState } from "@/config/loading-state";

export default {
  name: "App",
  components: {
    NavigationModule,
    LoaderModule,
  },

  data() {
    return {
      isLoading: false,
      showFooter: true,
      cacheView: true,
    };
  },

  created() {
    // if (this.checkIE()) {
    //   window.addEventListener(
    //     "hashchange",
    //     () => {
    //       var currentPath = window.location.hash.slice(1);
    //       if (this.$route.path !== currentPath) {
    //         this.$router.push(currentPath);
    //       }
    //     },
    //     false
    //   );
    // }

    LoadingState.$on("toggle", (isLoading) => {
      this.isLoading = isLoading;
    });
  },

  watch: {
    $route(to) {
      this.showFooter = !to.meta.hideFooter;
      this.cacheView = !to.meta.noCache;
    },
  },

  methods: {
    checkIE() {
      return (
        "-ms-scroll-limit" in document.documentElement.style &&
        "-ms-ime-align" in document.documentElement.style
      );
    },
  },
};
</script>
<style>
#app {
  width: 100%;
  height: 100%;
}
</style>

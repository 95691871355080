import Vue from "vue";
import template from "./navigation.html";
import { namespaceHelpers } from "@/store/modules/user";

import "./navigation.scss";

export default Vue.component("NavigationModule", {
  template,

  data() {
    return {
      rightNavigation: [
        {
          title: "Login",
          link: `/${this.$i18n.locale}/login`,
          icon: "mdi-login",
          requiresAuth: false,
        },
        {
          title: "Signup",
          link: `/${this.$i18n.locale}/signup`,
          icon: "mdi-account-plus",
          requiresAuth: false,
        },
        {
          title: "Profile",
          link: `/${this.$i18n.locale}/profile`,
          icon: "mdi-account-circle",
          requiresAuth: true,
        },
        {
          title: "Logout",
          action: this.onLogout,
          icon: "mdi-logout",
          requiresAuth: true,
        },
      ],
      leftNavigation: [
        {
          title: "Templates",
          link: `/${this.$i18n.locale}/templates`,
          icon: "mdi-image-multiple",
          requiresAuth: true,
        },
        {
          title: "MediaPool",
          link: `/${this.$i18n.locale}/media-pool`,
          icon: "mdi-cloud",
          requiresAuth: true,
        },
        {
          title: "Channels",
          link: `/${this.$i18n.locale}/channels`,
          icon: "mdi-layers",
          requiresAuth: true,
        },
      ],
    };
  },

  computed: {
    ...namespaceHelpers.mapState({
      user: (state) => state.data,
    }),
    authenticated() {
      return this.user.access_token;
    },
  },
  methods: {
    ...namespaceHelpers.mapActions({ logout: "reset" }),

    async onLogout() {
      await this.logout();
      window.localStorage.removeItem("vuex");
      await this.$router.replace(`/${this.$i18n.locale}`);
    },

    navItemActive(navItem) {
      return (
        (this.authenticated && navItem.requiresAuth) ||
        (!this.authenticated && !navItem.requiresAuth)
      );
    },
  },
});

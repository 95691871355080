import axios from "axios";
import { API_BASE } from "@/config/constants";
import { getAuthentication, setLoading } from "@/util/helpers";

function setupInterceptors(instance) {
  instance.interceptors.request.use(
    (config) => {
      setLoading(true);
      const authHeader = getAuthentication();
      if (!config.headers.Authorization && authHeader) {
        config.headers = {
          ...config.headers,
          Authorization: authHeader,
        };
      }
      return config;
    },
    (error) => {
      setLoading(false);
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      setLoading(false);
      return response;
    },
    (error) => {
      setLoading(false);
      return Promise.reject(error);
    }
  );
}

export const authResource = axios.create({
  baseURL: `${API_BASE}/uaa`,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: "Basic YnJvd3Nlcjpicm93c2Vy",
  },
});
setupInterceptors(authResource);

export const profileResource = axios.create({
  baseURL: `${API_BASE}/uaa`,
  headers: {
    "Content-Type": "application/json",
  },
});
setupInterceptors(profileResource);

export const templateResource = axios.create({
  baseURL: `${API_BASE}/templates`,
  headers: {
    "Content-Type": "application/json",
  },
});
setupInterceptors(templateResource);

export const rendererResource = axios.create({
  baseURL: `${API_BASE}/content-rendering-service/v1/renderer`,
});
setupInterceptors(rendererResource);

export const dataSourceResource = axios.create({
  baseURL: `${API_BASE}/generator/data-sources`,
  headers: {
    "Content-Type": "application/json",
  },
});
setupInterceptors(dataSourceResource);

export const inputChannelResource = axios.create({
  baseURL: `${API_BASE}/input-channel-service/v1/input-channel`,
  headers: {
    "Content-Type": "application/json",
  },
});
setupInterceptors(inputChannelResource);

export const outputChannelResource = axios.create({
  baseURL: `${API_BASE}/output-channel-service/v1/output-channel`,
  headers: {
    "Content-Type": "application/json",
  },
});
setupInterceptors(outputChannelResource);

export const schedulerResource = axios.create({
  baseURL: `${API_BASE}/generator/scheduler`,
  headers: {
    "Content-Type": "application/json",
  },
});
setupInterceptors(schedulerResource);

export const authenticationResource = axios.create({
  baseURL: `${API_BASE}/generator/authentication`,
  headers: {
    "Content-Type": "application/json",
  },
});
setupInterceptors(authenticationResource);

export const contentResource = axios.create({
  baseURL: `${API_BASE}/content`,
  headers: {
    "Content-Type": "application/json",
  },
});
setupInterceptors(contentResource);

export const contentUploadResource = axios.create({
  baseURL: `${API_BASE}/content`,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});
setupInterceptors(contentUploadResource);

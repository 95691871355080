export const types = {
  mutations: {
    SUCCESS: "setUser__SUCCESS",
    RESET: "setUser__SUCCESS_LOGOUT",
    FAILURE: "setUser__FAILURE",
    REQUEST: "setUser__REQUEST",
  },
  actions: {
    login: "user/login",
    reset: "user/reset",
    refreshToken: "user/refreshToken",
  },
  getters: {
    user: "user",
    isAuthenticated: "user/isAuthenticated",
  },
};

import { actions, mutations } from "../types";

export default {

  //settings menu switch
  [actions.showSettingsMenu]({ commit }, payload) {
    commit(mutations.showSettingsMenu, payload);
  },

  [actions.setCanvas]({ commit }, payload) {
    commit(mutations.setCanvas, payload);
  },

  [actions.requestError]({ commit }, payload) {
    commit(mutations.setRequestError, payload);
  }

};

import Login from "@/pages/Login/login";
import NotFound from "@/pages/NotFound/notFound";
import PreviewPage from "@/pages/PreviewPage/preview";
import Profile from "@/pages/Profile/profile";
import SuccessPage from "@/pages/SuccessPage/successPage";
import TemplateOverview from "@/pages/TemplateOverview/templateOverview";
import { store } from "@/store";
import Vue from "vue";
import Router from "vue-router";
import { LANGUAGES } from "./config/constants";
import { i18n } from "./i18n";
import { types } from "./store/modules/user/types";

Vue.use(Router);

const routes = [
  {
    path: "/:lang",
    name: "home",
    component: Login,
    meta: {
      home: true,
      guest: true,
    },
  },
  {
    path: "/:lang/login",
    name: "login",
    component: Login,
    meta: {
      home: true,
      guest: true,
    },
  },
  {
    path: "/:lang/success/:action",
    name: "confirmation",
    component: SuccessPage,
    meta: {
      guest: true,
    },
  },
  {
    path: "/:lang/profile",
    name: "profile",
    component: Profile,
    meta: {
      requires_auth: true,
    },
  },
  {
    path: "/:lang/signup",
    name: "signup",
    component: () =>
      import(/* webpackChunkName: "signup"*/ "@/pages/Signup/signup"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/:lang/templates/:id/slides/:slideId",
    name: "templates.editor",
    component: () =>
      import(
        /* webpackChunkName: "templateEditor"*/ "@/components/TemplateEditor/templateEditor"
      ),
    meta: {
      requires_auth: true,
      hideFooter: true,
      noCache: true,
    },
  },
  {
    path: "/:lang/templates/:id/slides/:slideId/preview",
    name: "templates.editor.preview",
    component: PreviewPage,
    meta: {
      requires_auth: true,
      hideFooter: false,
      noCache: true,
    },
  },
  {
    path: "/:lang/templates",
    name: "templates",
    component: TemplateOverview,

    meta: {
      requires_auth: true,
    },
  },
  {
    path: "/:lang/media-pool",
    name: "mediapool",
    component: () =>
      import(/* webpackChunkName: "mediaPool"*/ "@/pages/MediaPool/mediaPool"),
    meta: {
      requires_auth: true,
    },
  },
  {
    path: "/:lang/channels",
    name: "channels-root",
    redirect: (to) => `/${to.params.lang}/channels/input`,
    meta: {
      requires_auth: true,
    },
  },
  {
    path: "/:lang/channels/input",
    name: "channels-input",
    component: () =>
      import(/* webpackChunkName: "channels"*/ "@/pages/Channels/ChannelPage"),
    meta: {
      requires_auth: true,
    },
  },
  {
    path: "/:lang/channels/input",
    name: "channels-input-new",
    component: () =>
      import(/* webpackChunkName: "channels"*/ "@/pages/Channels/ChannelPage"),
    meta: {
      requires_auth: true,
    },
  },
  {
    path: "/:lang/channels/output",
    name: "channels-output",
    component: () =>
      import(/* webpackChunkName: "channels"*/ "@/pages/Channels/ChannelPage"),
    meta: {
      requires_auth: true,
    },
  },
  {
    path: "/:lang/channels/authentication",
    name: "channels-authentication",
    component: () =>
      import(/* webpackChunkName: "channels"*/ "@/pages/Channels/ChannelPage"),
    meta: {
      requires_auth: true,
    },
  },
  {
    path: "/:lang/*",
    name: "404",
    component: NotFound,
    meta: {
      guest: true,
    },
  },
  {
    path: "*",
    redirect: "/en",
  },
];

const route = new Router({
  routes: routes,
});

route.beforeEach(async (to, from, next) => {
  await store.dispatch(types.actions.refreshToken);
  const loggedIn = store.getters[types.getters.isAuthenticated];
  if (loggedIn) {
    if (to.name === "home" || to.path === "/" || to.path === "") {
      next({
        path: `/${to.params.lang}/templates`,
      });
    } else {
      next();
    }
  } else {
    if (to.matched.some((record) => record.meta.requires_auth)) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  }

  if (!LANGUAGES.includes(to.params.lang)) {
    next({
      path: to.path.replace(to.params.lang, LANGUAGES[0]),
    });
  }

  if (i18n.locale !== to.params.lang) {
    i18n.locale = to.params.lang;
  }
});

export const router = route;

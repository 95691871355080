// import { router } from '@/router';
import { types } from './types';

export const mutations = {
	[types.mutations.REQUEST](state) {
		state.isLoading = true;
		state.isLoaded = false;
		state.error = {};
		state.data = {};
	},
	[types.mutations.FAILURE](state, payload) {
		state.isLoading = false;
		state.isLoaded = true;
		state.error = payload.response.data;
		state.data = {};
	},
	[types.mutations.SUCCESS](state, payload) {
		state.isLoading = false;
		state.isLoaded = true;
		state.error = {};
		state.data = {
			...payload.data,
			refresh_time: new Date().getTime() + payload.data.expires_in * 1000
		};
	},
	[types.mutations.RESET](state) {
		state.isLoading = false;
		state.isLoaded = false;
		state.error = {};
		state.data = {};
	}
};

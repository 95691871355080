import { authResource } from "@/util/resources";
import { types } from "./types";

export const actions = {
  async login({ commit }, payload) {
    commit(types.mutations.REQUEST);
    try {
      const response = await authResource.post("/oauth/token", payload);
      await commit(types.mutations.SUCCESS, response);
      return response;
    } catch (error) {
      commit(types.mutations.FAILURE, error);
      return error;
    }
  },

  reset({ commit }) {
    commit(types.mutations.RESET);
  },

  async refreshToken({ commit, state }) {
    if (state.data && state.data.refresh_time <= new Date().getTime()) {
      try {
        const request = new FormData();
        request.append("grant_type", "refresh_token");
        request.append("refresh_token", state.data.refresh_token);
        const response = await authResource.post("/oauth/token", request);
        commit(types.mutations.SUCCESS, response);
      } catch (error) {
        commit(types.mutations.FAILURE, error);
      }
    }
  },
};

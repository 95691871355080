import { getters }  from "../types";

export default {
   [getters.getCurrentSettingsMenu]: (state) => {
    return state.currentSettingsMenu;
  },

  [getters.requestError]: (state) => {
    return state.requestError;
  }
}

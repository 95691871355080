import "babel-polyfill";
import Vue from "vue";
// import colors from 'vuetify/es5/util/colors';
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import prettyBytes from "vue-filter-pretty-bytes";

import { store } from "./store";
import { i18n } from "./i18n";
import { router } from "./router";
import "@/style.scss";
import App from "./App";
import DatetimePicker from "vuetify-datetime-picker";
import "vuetify-datetime-picker/src/stylus/main.styl";
import vuetify from "@/plugins/vuetify";
import { createMovableDialogs } from "./util/helpers";
import VImgWithFallback from "@/components/base/VImgWithFallback";

Vue.use(DatetimePicker);
Vue.use(VueMoment, {
  moment,
});
Vue.use(prettyBytes);

Vue.component("VImgWithFallback", VImgWithFallback);

Vue.filter("ellipsisMiddle", (value, maxLength) => {
  if (!value) return value;
  if (maxLength < 1) return value;
  if (value.length <= maxLength) return value;
  if (maxLength == 1) return value.substring(0, 1) + "...";

  var midpoint = Math.ceil(value.length / 2);
  var toremove = value.length - maxLength;
  var lstrip = Math.ceil(toremove / 2);
  var rstrip = toremove - lstrip;
  return value.substring(0, midpoint - lstrip) + "..." + value.substring(midpoint + rstrip);
});

createMovableDialogs();

new Vue({
  el: "#app",
  vuetify,
  router,
  store,
  i18n,
  template: "<App/>",
  components: {
    App,
  },

  render: (h) => h(App),
});

import { createNamespacedHelpers } from "vuex";
import { actions } from "./templates/actions";
import { mutations } from "./templates/mutations";
import { getters } from "./templates/getters";

export const namespaceHelpers = createNamespacedHelpers("templates");

export const templates = {
  namespaced: true,
  state: {
    isLoading: false,
    isLoaded: false,
    data: [],
    currentSelectedComponent: null,
    currentEditMenuComponent: null,
    error: {},
  },
  mutations,
  actions,
  getters,
};

import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import { inputChannelResource } from "../../util/resources";
export const namespaceHelpers = createNamespacedHelpers("inputChannelsNew");

export const types = {
  mutations: {
    REQUEST: "inputChannels__REQUEST",
    FAILURE: "inputChannels__FAILURE",
    SUCCESS: "inputChannels__SUCCESS",
    SUCCESS_EDIT: "inputChannels__SUCCESS_EDIT",
    SUCCESS_DELETE: "inputChannels__SUCCESS_DELETE",
    SUCCESS_UPLOAD: "inputChannels__SUCCESS_UPLOAD",
    SUCCESS_SET_DATASOURCE: "inputChannels__SUCCESS_SET_DATASOURCE",
    DELETE_INPUT_CHANNEL: "inputChannels__DELETE_INPUT_CHANNEL",
    SET_PREVIEW_INDEX: "inputChannels__SET_PREVIEW_INDEX",
    SET_IMAGE_SIZE: "inputChannels__SET_IMAGE_SIZE",
  },
};

export const inputChannels = {
  namespaced: true,
  state: {
    isLoading: false,
    isLoaded: false,
    previewIndex: 0,
    data: [],
    dataSourceBody: {},
    error: {},
    imageSizes: {},
  },
  mutations: {
    [types.mutations.REQUEST](state) {
      state.isLoading = true;
      state.isLoaded = false;
      state.error = {};
    },

    [types.mutations.FAILURE](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = payload.response.data;
    },

    [types.mutations.SUCCESS](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      state.data = payload.data;
    },

    [types.mutations.SUCCESS_SET_DATASOURCE](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      state.dataSourceBody[payload.id] = payload;
      Vue.set(state.dataSourceBody, payload.id, payload);
    },

    [types.mutations.SUCCESS_UPLOAD](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      Vue.set(state.data, state.data.length, payload.data);
    },

    [types.mutations.SUCCESS_EDIT](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      const index = state.data.findIndex((val) => val.id === payload.data.id);
      Vue.set(state.data, index, payload.data);
    },

    [types.mutations.SUCCESS_DELETE](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      const index = state.data.findIndex((val) => val.id === payload);
      Vue.delete(state.data, index);
    },

    [types.mutations.SET_PREVIEW_INDEX](state, payload) {
      state.previewIndex = Math.max(0, payload);
    },

    [types.mutations.SET_IMAGE_SIZE](state, payload) {
      Vue.set(
        state.imageSizes,
        `${payload.id}-${payload.url}`,
        payload.imageSize
      );
    },
  },
  getters: {
    inputChannels: (state) => state.data,
    inputChannelWithBody: (state) => (id) => state.dataSourceBody[id],
    previewIndex: (state) => state.previewIndex,
    inputChannelComponentValue:
      (state) =>
      (component, currentSlide, applyPrefixSuffix = true) => {
        const dataSource = state.data.find(
          (data) => data.id === component.dataSourceId
        );
        if (!dataSource) {
          return "";
        }
        let dataIndex = 0;
        let contentIndex = 0;
        let dataCountIndex = 0;
        for (let data of dataSource.data) {
          dataCountIndex += data.data.length;
          if (dataCountIndex >= state.previewIndex) {
            contentIndex =
              state.previewIndex - (dataCountIndex - data.data.length);
            break;
          }
          ++dataIndex;
          if (dataIndex >= dataSource.data.length) {
            dataIndex = dataSource.data.length - 1;
            contentIndex = dataSource.data[dataIndex].data.length - 1;
            break;
          }
        }
        const data =
          dataSource.data[Math.min(dataIndex, dataSource.data.length - 1)];

        if (component.properties.relativeSelectorIndex && data.data.length) {
          let unboundedContentIndex =
            contentIndex + +component.properties.relativeSelectorIndex;
          while (unboundedContentIndex < 0) {
            unboundedContentIndex += data.data.length;
          }
          contentIndex = unboundedContentIndex % data.data.length;
        }

        let sourceText = "";
        if (
          component.dataSourceSelector.startsWith(
            "additionalRequestParameters."
          ) &&
          data.requestParameterSelectors
        ) {
          const fittingSelector = data.requestParameterSelectors.find(
            (selector) =>
              selector.selector ===
              component.dataSourceSelector.substring(
                "additionalRequestParameters.".length
              )
          );
          if (fittingSelector) {
            sourceText = fittingSelector.value || "";
          }
        }
        if (!sourceText.length) {
          let entryIndex = contentIndex;
          const processedDatasourceGroups = [];
          const componentsWithSameSourceId = currentSlide.components.filter(
            (entry) => entry.dataSourceId === component.dataSourceId
          );
          for (const entry of componentsWithSameSourceId) {
            if (component.dataSourceGroup === entry.dataSourceGroup) {
              break;
            }
            if (processedDatasourceGroups.includes(entry.dataSourceGroup)) {
              continue;
            }
            ++entryIndex;
            processedDatasourceGroups.push(entry.dataSourceGroup);
          }
          sourceText =
            data.data[Math.min(entryIndex, data.data.length - 1)][
              component.dataSourceSelector
            ];
        }
        if (sourceText == null) {
          return "";
        }
        if (
          applyPrefixSuffix &&
          component.properties.stringFormatter &&
          component.properties.stringFormatter === "PREFIX/SUFFIX"
        ) {
          if (component.properties.prefixString) {
            sourceText = `${component.properties.prefixString}${sourceText}`;
          }
          if (component.properties.suffixString) {
            sourceText = `${sourceText}${component.properties.suffixString}`;
          }
        }
        return ("" + sourceText)
          .replace(/&amp;/g, "&")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, '"')
          .replace(/&#39;/g, "'")
          .replace(/&#x2F;/g, "/");
      },
    // this.inputChannelWithBody(this.component.dataSourceId).body[this.component.dataSourceIndex]
    inputChannel: (state) => (id) => state.data.find((data) => data.id === id),
    referencedAuthentication: (state) => {
      let reference = new Set();
      state.data.forEach((channel) => {
        if (channel.authentication) reference.add(channel.authentication.id);
      });
      return reference;
    },
    hideConditionMet: (state, getters) => (slide, component) => {
      if (
        !component.properties.enabledHideCondition ||
        !component.properties.hideConditionShowOrHide ||
        !component.properties.hideConditionComponentId ||
        !component.properties.hideConditionComparator
      ) {
        return false;
      }
      const componentToCompare = slide.components.find(
        (entry) => entry.id === component.properties.hideConditionComponentId
      );
      if (!componentToCompare) {
        return false;
      }
      const originalValue =
        "" +
        getters.inputChannelComponentValue(componentToCompare, slide, false);
      const valueToCompare =
        component.properties.hideConditionValue != null
          ? component.properties.hideConditionValue
          : "";

      let hide = false;
      if (component.properties.hideConditionComparator === "EQUALS") {
        hide = originalValue != valueToCompare;
      } else if (
        component.properties.hideConditionComparator === "NOT_EQUALS"
      ) {
        hide = originalValue == valueToCompare;
      } else if (
        component.properties.hideConditionComparator === "ORIENTATION"
      ) {
        const imageSizeToCompare =
          state.imageSizes[`${componentToCompare.id}-${originalValue}`];
        if (imageSizeToCompare == null) {
          return false;
        }
        if (valueToCompare === "PORTRAIT") {
          hide = imageSizeToCompare.width > imageSizeToCompare.height;
        } else if (valueToCompare === "LANDSCAPE") {
          hide = imageSizeToCompare.width < imageSizeToCompare.height;
        } else if (valueToCompare === "PORTRAIT_OR_SQUARE") {
          hide = imageSizeToCompare.width >= imageSizeToCompare.height;
        } else if (valueToCompare === "LANDSCAPE_OR_SQUARE") {
          hide = imageSizeToCompare.width <= imageSizeToCompare.height;
        }
      }

      if (component.properties.hideConditionShowOrHide !== "SHOW") {
        return !hide;
      }
      return hide;
    },
  },
  actions: {
    async load({ commit }) {
      try {
        const res = await inputChannelResource.get("");
        commit(types.mutations.SUCCESS, res);
      } catch (err) {
        commit(types.mutations.FAILURE, err);
      }
    },

    async save({ commit }, dataSource) {
      try {
        const res = await inputChannelResource.post("", dataSource);
        commit(types.mutations.SUCCESS_UPLOAD, res);
        return res;
      } catch (err) {
        commit(types.mutations.FAILURE, err);
        return err;
      }
    },

    async edit({ commit }, dataSource) {
      try {
        const res = await inputChannelResource.put(dataSource.id, dataSource);
        commit(types.mutations.SUCCESS_EDIT, res);
        return res;
      } catch (err) {
        commit(types.mutations.FAILURE, err);
        return err;
      }
    },

    async delete({ commit }, dataSource) {
      try {
        const res = await inputChannelResource.delete(`/${dataSource.id}`);
        commit(types.mutations.SUCCESS_DELETE, dataSource.id);
        return res;
      } catch (err) {
        commit(types.mutations.FAILURE, err);
        return err;
      }
    },

    async getDataSourceBody({ commit, state }, dataSource) {
      try {
        if (
          state.dataSourceBody[dataSource.id] != null &&
          state.dataSourceBody[dataSource.id].body.length > 0
        ) {
          return state.dataSourceBody[dataSource.id];
        }
        const res = await inputChannelResource.get(`/${dataSource.id}`);
        commit(types.mutations.SUCCESS_SET_DATASOURCE, res.data);
        return res.data[0];
      } catch (err) {
        return err;
      }
    },

    setPreviewIndex({ commit }, index) {
      commit(types.mutations.SET_PREVIEW_INDEX, index);
    },

    setImageSize({ commit }, data) {
      commit(types.mutations.SET_IMAGE_SIZE, data);
    },
  },
};

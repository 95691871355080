// A global API-root
//use this in local development mode

export const API_BASE = process.env.VUE_APP_BASE_URL;

export const LANGUAGES = ["en", "de"];

export const FONTS = [
  {
    text: "Arial",
    value: "Arial,sans-serif",
  },
  {
    text: "Times",
    value: "Times,serif",
  },
  {
    text: "Courier",
    value: "Courier,monospace",
  },
  {
    text: "Roboto",
    value: "Roboto,sans-serif",
  },
];
export const ARROW_MOVEMENT_STOP_WIDTH = 1; // in real pix

export const PLACEHOLDER_IMAGE_SIZE = {
  width: 400,
  height: 225,
};

export const DEFAULT_FONT_SIZE = 40;

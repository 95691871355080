import Vue from 'vue';
import template from './sortToolbar.html';

export default Vue.component('sort-toolbar', {
	template,
	props: [ 'title', 'initialSort', 'isLoading' ],
	data: function() {
		return {
			sort: this.initialSort
		};
	},
	methods: {
		changeSort(value) {
			this.sort = value;
			this.$emit('sortingChanged', value);
		}
	}
});

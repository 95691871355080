import moment from "moment";
import { createNamespacedHelpers } from "vuex";

import { isEmpty } from "@/util/helpers";
import { contentResource } from "@/util/resources";
import { detailedDiff } from "deep-object-diff";
export const namespaceHelpers = createNamespacedHelpers("mediaPool");

export const types = {
  mutations: {
    REQUEST: "mediaPool__REQUEST",
    FAILURE: "mediaPool__FAILURE",
    SUCCESS: "mediaPool__SUCCESS",
    SUCCESS_UPLOAD: "mediaPool__SUCCESS_UPLOAD",
    DELETE_FILE: "mediaPool__DELETE_FILE",
  },
};
export const mediaPool = {
  namespaced: true,
  state: {
    isLoading: false,
    isLoaded: false,
    data: [],
    error: {},
  },
  mutations: {
    [types.mutations.REQUEST](state) {
      state.isLoading = true;
      state.isLoaded = false;
      state.error = {};
    },

    [types.mutations.FAILURE](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = payload.response.data;
    },

    [types.mutations.SUCCESS](state, payload) {
      state.isLoading = false;
      state.isLoaded = true;
      state.error = {};
      const lastLoadTime = moment(state.lastLoaded);

      if (!state.lastLoaded || moment().diff(lastLoadTime, "days") >= 3) {
        state.data = payload.data._embedded.files;
        state.lastLoaded = moment().format();
      } else {
        const diff = detailedDiff(state.data, payload.data._embedded.files);
        if (
          !isEmpty(diff.added) ||
          !isEmpty(diff.deleted) ||
          (diff.updated["0"] !== undefined && diff.updated["0"].metadata !== undefined)
        ) {
          state.data = payload.data._embedded.files;
          state.lastLoaded = moment().format();
        }
      }
    },

    [types.mutations.SUCCESS_UPLOAD](state, payload) {
      const fileToDelete = state.data.find((element) => element.key === payload.key);
      if (fileToDelete) {
        state.data.splice(state.data.indexOf(fileToDelete), 1);
      }
      state.data.push(payload);
    },

    [types.mutations.DELETE_FILE](state, payload) {
      const fileToDelete = state.data.find((element) => element.key === payload);
      if (fileToDelete) {
        state.data.splice(state.data.indexOf(fileToDelete), 1);
      }
    },
  },
  getters: {
    files: (state) => (mediaType, sort, searchText) => {
      return (mediaType === null || mediaType === undefined || mediaType === "none"
        ? state.data
        : state.data.filter((file) => file && file.contentType && file.contentType.startsWith(mediaType))
      )
        .filter((item) => item.key.toLowerCase().indexOf((searchText || "").toLowerCase()) > -1)
        .filter((item) => !item.key.startsWith("rendition/"))
        .filter((item) => !item.key.startsWith("preview/"))
        .sort((item1, item2) =>
          sort === "uploaded"
            ? moment(item2.lastModified).diff(moment(item1.lastModified))
            : item1.key.localeCompare(item2.key)
        );
    },

    fonts: (_, getters) => {
      const fonts = getters.files("font");
      return fonts.map((font) => {
        const fontFamily = font.key.substring(0, font.key.lastIndexOf("."));
        return {
          text: font.key,
          value: fontFamily,
          style: { fontFamily: `'${fontFamily}'` },
        };
      });
    },

    fileForKey: (state) => (fileKey) => state.data.find((elem) => elem.key == fileKey),

    filters: (state) => (filters) => {
      filters.forEach((filter) => {
        if (filter.contentType !== "none") {
          filter.enabled = false;
        }
      });
      state.data.forEach((element) => {
        const filterIndex = filters.findIndex((filter) => element.contentType.startsWith(filter.contentType));
        if (filterIndex >= 0) {
          filters[filterIndex].enabled = true;
        }
      });
      return filters;
    },
  },
  actions: {
    async load({ commit }) {
      try {
        const response = await contentResource.get("/files");
        commit(types.mutations.SUCCESS, response);
      } catch (error) {
        commit(types.mutations.FAILURE, error);
      }
    },

    async delete({ commit, state }, payload) {
      const fileToDelete = state.data.find((element) => element.key === payload);
      if (fileToDelete) {
        try {
          await contentResource.delete(`/files/${fileToDelete.bucketName}/${fileToDelete.key}`);
          commit(types.mutations.DELETE_FILE, payload);
        } catch (error) {
          commit(types.mutations.FAILURE, error);
        }
      }
    },

    uploadSuccess({ commit }, payload) {
      commit(types.mutations.SUCCESS_UPLOAD, payload);
    },

    async mountFonts({ getters }) {
      const files = getters.files("font");
      if (document.getElementById("cb-fonts-loader") === null) {
        const newStyle = document.createElement("style");
        newStyle.setAttribute("id", "cb-fonts-loader");
        newStyle.setAttribute("type", "text/css");
        let fonts = "";

        files.forEach((file) => {
          const fontFamily = file.key.substring(0, file.key.lastIndexOf("."));
          fonts += `@font-face { font-family: '${fontFamily}'; src: url('${file._links.url.href}'); }`;
        });
        newStyle.appendChild(document.createTextNode(fonts));
        document.head.appendChild(newStyle);
      }
    },
  },
};

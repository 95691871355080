export const UNDO = 'undo';

class UndoRedoManager {
	undoStack = {};
	redoStack = {};

	lastId = null;

	// push initial state to come back to

	// constructor(state) {
	// 	this.undoStack.push(state);
	// }

	registerModules(modules) {
		for (let module of modules) {
			this.undoStack[module] = [];
			this.redoStack[module] = [];
		}
	}

	pushState(module, state) {
		// let state = data.data.find((elem) => (elem.id = payload.templateId));
		state = JSON.parse(JSON.stringify(state));

		this.undoStack[module].push(state);
		this.redoStack[module] = [];
	}

	async undo({ commit, state }, module) {
		if (!this.canUndo(module)) {
			return;
		}
		const undoData = this.undoStack[module].splice(this.undoStack[module].length - 1, 1)[0];
		this.redoStack[module].push(state[module].data);

		await commit(UNDO, { module: module, data: undoData });
	}

	async redo({ commit }, module) {
		if (!this.canRedo(module)) {
			return;
		}
		const undoData = this.redoStack[module].splice(this.redoStack[module].length - 1, 1)[0];
		await commit(UNDO, { module: module, data: undoData });
	}

	canUndo(module) {
		return this.undoStack[module] !== undefined && this.undoStack[module].length > 0;
	}

	canRedo(module) {
		return this.redoStack[module] !== undefined && this.redoStack[module].length > 0;
	}
}
export const UndoRedo = new UndoRedoManager();
